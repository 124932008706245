/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './Api.scss'

import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


const prefix = window.location.protocol + "//" + window.location.host;
let swag_url = new URL('/text/swagger.json', prefix);
let swagStat_url = new URL('/text/swagstat.json', prefix);
console.log(swag_url);
class Api extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <React.Fragment>
                <div className="api-container">
                    <div className="api-content">
                        <Row>
                            <Col lg={12}>
                                <SwaggerUI url={swag_url.href} /> 
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <SwaggerUI url={swagStat_url.href} /> 
                            </Col>
                        </Row>

                    </div>
                </div>

            </React.Fragment>


        )
    }
}

export default Api;




